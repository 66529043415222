import { fromEvent, Subscription } from 'rxjs';

import {
  EmbeddableInputEvent,
  EmbeddableOutputEvent,
  EmbeddableOutputEventType
} from '@24sessions/common';

import { Logger } from '../logger';
import { Omnichannel } from './models';

/**
 * AppInputConnector
 * =============================================================================
 * Listen for event messages emitted from within the Omnichannel iframe.
 */

export class AppInputConnector {
  private activeSubscription?: Subscription;

  constructor(
    readonly appOrigin: string,
    private omnichannel: Omnichannel,
    private logger: Logger
  ) {}

  listen(): void {
    this.stopListening();

    this.activeSubscription = fromEvent<MessageEvent>(window, 'message')
      .subscribe((event: MessageEvent<EmbeddableOutputEvent>) => {
        if (event.origin !== this.appOrigin) {
          // NOTE(mark 2022-01-13): uncomment if needed, this is spammy in development
          // this.logger.log(`Received message from: ${event.origin}, ignoring`, event);
          return;
        }

        // NOTE(mark 2022-01-13): uncomment if needed, this is spammy in development
        // this.logger.log(`Received message: ${event.data.type}, data: `, event.data);

        switch (event.data.type) {
          case EmbeddableOutputEventType.MeetingRoomLoaded:
            this.omnichannel.onMeetingRoomLoaded(event);
            break;
          case EmbeddableOutputEventType.EnteringMeeting:
            this.omnichannel.onEnteringMeeting(event);
            break;
          case EmbeddableOutputEventType.EnteredMeeting:
            this.omnichannel.onMeetingEntered(event);
            break;
          case EmbeddableOutputEventType.EnteringMeetingFailed:
            this.omnichannel.onEnteringMeetingFailed(event);
            break;
          case EmbeddableOutputEventType.MeetingLeft:
            this.omnichannel.onMeetingLeft(event);
            break;
          case EmbeddableOutputEventType.Theme:
            this.omnichannel.onReceivedTheme(event);
            break;
          case EmbeddableOutputEventType.Resize:
            this.omnichannel.onResize(event);
            break;
          case EmbeddableOutputEventType.MutedStates:
            this.omnichannel.onMutedStates(event);
            break;
          case EmbeddableOutputEventType.CallType:
            this.omnichannel.onCallType(event);
            break;
          case EmbeddableOutputEventType.ParticipantsUpdate:
            this.omnichannel.onParticipantUpdate(event);
            break;
          case EmbeddableOutputEventType.StartCobrowsing:
            this.omnichannel.onStartCobrowsing(event);
            break;
          case EmbeddableOutputEventType.StopCobrowsing:
            this.omnichannel.onStopCobrowsing(event);
            break;
          case EmbeddableOutputEventType.SetLeaveConfirmation:
            this.omnichannel.setLeavePageConfirmation(event.data.enabled);
            break;
          case EmbeddableOutputEventType.OpenWidget:
            this.omnichannel.onOpenWidget();
            break;
          case EmbeddableOutputEventType.CloseWidget:
            this.omnichannel.onCloseWidget();
            break;
          default:
            break;
        }
      });
  }

  stopListening(): void {
    this.activeSubscription?.unsubscribe();
    this.activeSubscription = undefined;
  }
}

export class AppOutputConnector {
  constructor(
    readonly appOrigin: string,
    private iframe: HTMLIFrameElement,
    private logger: Logger
  ) {}

  sendMessage(event: EmbeddableInputEvent): void {
    this.iframe.contentWindow?.postMessage(event, this.appOrigin);
  }
}

export type CreateInputConnector = (
  appOrigin: string, omnichannel: Omnichannel, logger: Logger) => AppInputConnector;

export type CreateOutputConnector = (appOrigin: string, iframe: HTMLIFrameElement, logger: Logger) => AppOutputConnector;

export const createAppInputConnector: CreateInputConnector = (appOrigin, omnichannelFacade, logger) => {
  return new AppInputConnector(appOrigin, omnichannelFacade, logger);
};

export const createAppOutputConnector: CreateOutputConnector = (appOrigin, iframe, logger) => {
  return new AppOutputConnector(appOrigin, iframe, logger);
};
