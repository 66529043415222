
import { EventType, IncrementalSource } from 'rrweb';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Socket } from 'socket.io-client';
import { event as DOMChangeEvent } from 'rrweb/typings/types';

import {
  CoBrowseDisconnectReason,
  CoBrowseHostSession,
  CoBrowseHostSessionAgentDataChange,
  CoBrowseHostSessionStatus,
  CoBrowseHostSessionStatusChange,
  CoBrowsingEventType,
} from '@24sessions/common';

import { CanvasOverlay } from './canvas-overlay';
import {
  CoBrowsingRemoteConfig,
  CreateConnectionFn,
  Point,
  SequentialPoint,
  ScrollCoordinates,
} from './models';
import { TemporarySwitch, timeoutPromisified } from '../utils';
import { Recorder } from './recorder';
import { RemoteCursor } from './remote-cursor';
import './co-browse-session.scss';

function xpathSelector<T extends Node>(path: string): T | null {
  return document.evaluate(
    path,
    document,
    null,
    XPathResult.FIRST_ORDERED_NODE_TYPE,
    null,
  ).singleNodeValue as T | null;
}

export class CoBrowseHostSessionImpl implements CoBrowseHostSession {
  static readonly overlayClassName = 'co-browse-overlay';

  static readonly coBrowseSessionBodyAttribute = 'data-co-browse-session';

  private fakeClickInvoked = false;

  private static activeSession?: CoBrowseHostSession;

  private recorder = new Recorder();

  private socket: Socket;

  private status = CoBrowseHostSessionStatus.Connecting;

  private statusChangesSource = new Subject<CoBrowseHostSessionStatusChange>();

  private destroySource = new Subject<void>();

  private connectedViewers = 0;

  private canvasOverlay: CanvasOverlay;

  private remoteCursor: RemoteCursor;

  private ignoreRemoteScrollSwitch = new TemporarySwitch(300);

  private stopTempSwitchListener: Function;

  private processedScroll: Point | null = null;

  private requestedScroll: Point | null = null;

  private disconnectReason?: CoBrowseDisconnectReason;

  private agentDataChangesSource = new Subject<CoBrowseHostSessionAgentDataChange>();

  readonly statusChanges = this.statusChangesSource.asObservable();

  readonly agentChanges = this.agentDataChangesSource.asObservable();

  readonly overlay: HTMLElement;

  get isDestroyed(): boolean {
    return this.destroySource.closed;
  }

  constructor(createConnection: CreateConnectionFn) {
    if (CoBrowseHostSessionImpl.activeSession) {
      throw new Error('There is an active co-browse session. Make sure it is destroyed before creating another session.');
    }

    this.socket = createConnection();

    this.overlay = document.createElement('div');
    this.overlay.classList.add(CoBrowseHostSessionImpl.overlayClassName, Recorder.alwaysBlockedClass);
    document.body.appendChild(this.overlay);

    this.canvasOverlay = new CanvasOverlay(this.overlay);
    this.remoteCursor = new RemoteCursor(this.overlay);

    this.setIncomingEvents();
    this.setOutgoingEvents();

    this.stopTempSwitchListener = this.ignoreRemoteScrollSwitch.onStateChanges(isOn => {
      if (!isOn) {
        this.handleNextScrollRequest();
      }
    });

    CoBrowseHostSessionImpl.activeSession = this;
  }

  private scrollElement(xpath: string, scrollPoint: Point): void {
    const element = xpathSelector<Element>(xpath);
    if (element) {
      element.scrollTo({
        left: scrollPoint.x,
        top: scrollPoint.y,
        behavior: 'smooth',
      });
    }
  }

  private setIncomingEvents(): void {
    this.socket.on(CoBrowsingEventType.Configuration, (config: Partial<CoBrowsingRemoteConfig>) => {
      if (config.agentData !== undefined) {
        this.remoteCursor.agentName(config.agentData.name)
        this.updateAgentData({ data: config.agentData })
      }
      this.recorder.start(config);
    });

    this.socket.on(CoBrowsingEventType.ClientClick, (xPath: string) => {
      const element = xpathSelector<HTMLElement>(xPath);
      if (element) {
        this.fakeClickInvoked = true;
        element.click();
        this.remoteCursor.fakeClick();
        this.fakeClickInvoked = false;
      }
    });

    this.socket.on(CoBrowsingEventType.ClientConnected, ({ count = 1 }: { count?: number }) => {
      this.socket.emit(CoBrowsingEventType.ClientConnected, {});
      this.recorder.restart();
      this.connectedViewers += count;
      this.setStatus({ status: CoBrowseHostSessionStatus.CoBrowsing });
    });

    this.socket.on(CoBrowsingEventType.ClientDraw, (point: SequentialPoint) => this.canvasOverlay.addDrawingPoint(point));

    this.socket.on(CoBrowsingEventType.ClientInput, ({ xPath, value }: { xPath: string, value: any }) => {
      const element = xpathSelector<HTMLInputElement>(xPath);
      if (!element) {
        return;
      }

      element.value = value;
      element.dispatchEvent(new Event('change'));
      element.animate(
        // keyframes
        [
          { transform: 'scale(1.2)' },
          { transform: 'scale(1)' },
        ],
        // timing options
        { duration: 200 },
      );
    });

    this.socket.on(CoBrowsingEventType.ClientMousemove, (point: Point) => this.remoteCursor.moveTo(point));

    this.socket.on(CoBrowsingEventType.ClientScroll, (point: ScrollCoordinates) => {
      if (point.xpath) {
        return this.scrollElement(point.xpath, point);
      }

      if (this.ignoreRemoteScrollSwitch.isOff) {
        this.processedScroll = point;
        window.scrollTo({ left: point.x, top: point.y, behavior: 'smooth' });
      } else if (this.processedScroll) {
        this.requestedScroll = point;
      }
    });

    this.socket.on(CoBrowsingEventType.HostChanged, () => this.disconnect(CoBrowseDisconnectReason.HostChanged));
    this.socket.on(CoBrowsingEventType.CloseSession, () => this.disconnect(CoBrowseDisconnectReason.SessionClosedRemotely));
    this.socket.on(CoBrowsingEventType.ClientDisconnected, () => {
      this.connectedViewers -= 1;
      if (this.connectedViewers < 1) {
        this.setStatus({ status: CoBrowseHostSessionStatus.WaitingForViewers });
      }
    });

    this.socket.on('connect', () => this.setStatus({ status: CoBrowseHostSessionStatus.WaitingForViewers }));
    this.socket.on('disconnect', () => this.completeDisconnect());

    this.socket.on('connect_error', async error => {
      await this.disconnect(CoBrowseDisconnectReason.ConnectionError);
      this.completeDisconnect();
      console.error('[CoBrowse] socket connection error: ' + error.message);
    });
  }

  private setOutgoingEvents(): void {
    this.recorder.updates
      .pipe(
        takeUntil(this.destroySource),
        filter(event => !this.isFakeClickEvent(event)),
      )
      .subscribe(event => {
        this.socket.emit(CoBrowsingEventType.DOMUpdate, event);

        if (event.type === EventType.IncrementalSnapshot && event.data.source === IncrementalSource.Scroll) {
          this.ignoreRemoteScrollSwitch.on();
        }
      });
  }

  private handleNextScrollRequest(): void {
    if (!this.processedScroll || !this.requestedScroll) {
      this.requestedScroll = null;
      this.processedScroll = null;
      return;
    }

    if (this.requestedScroll && window.scrollY === this.processedScroll.y && window.scrollX === this.processedScroll.x) {
      this.processedScroll = this.requestedScroll;
      this.requestedScroll = null;
      window.scrollTo({ left: this.processedScroll.x, top: this.processedScroll.y, behavior: 'smooth' });
    }
  }

  private setStatus(statusChange: CoBrowseHostSessionStatusChange): void {
    if (statusChange.status === this.status) {
      return;
    }

    this.status = statusChange.status;

    if (this.status !== CoBrowseHostSessionStatus.CoBrowsing) {
      this.connectedViewers = 0;
      this.hideInterface();
    } else {
      this.displayInterface();
    }

    this.statusChangesSource.next(statusChange);
  }

  private updateAgentData(agentDataChange: CoBrowseHostSessionAgentDataChange): void {
    this.agentDataChangesSource.next(agentDataChange)
  }

  private canDisconnect(): boolean {
    return ![CoBrowseHostSessionStatus.Disconnected, CoBrowseHostSessionStatus.Disconnecting].includes(this.status);
  }

  private async disconnect(reason: CoBrowseDisconnectReason): Promise<void> {
    if (!this.canDisconnect()) {
      console.warn('[CoBrowse] Received a disconnect request while the session is already closed or disconnecting.');
      return;
    }

    this.setStatus({ status: CoBrowseHostSessionStatus.Disconnecting });
    this.disconnectReason = reason;

    if (reason === CoBrowseDisconnectReason.SessionClosed) {
      this.socket.emit(CoBrowsingEventType.CloseSession, {});
      // don't immediately disconnect to allow the socket deliver the event
      await timeoutPromisified(25);
    }
    this.socket.disconnect().off();
  }

  private completeDisconnect(): void {
    if (typeof this.disconnectReason === 'undefined') {
      console.warn('[CoBrowse] Unexpected closing of a co-browse session.');
      this.disconnectReason = CoBrowseDisconnectReason.UnknownError;
    }

    this.setStatus({ status: CoBrowseHostSessionStatus.Disconnected, reason: this.disconnectReason });
  }

  private hideInterface(): void {
    document.body.setAttribute(CoBrowseHostSessionImpl.coBrowseSessionBodyAttribute, 'inactive');
  }

  private displayInterface(): void {
    document.body.setAttribute(CoBrowseHostSessionImpl.coBrowseSessionBodyAttribute, 'active');
  }

  private clearInterface(): void {
    this.overlay.remove();
    document.body.removeAttribute(CoBrowseHostSessionImpl.coBrowseSessionBodyAttribute);
  }

  isFakeClickEvent(event: DOMChangeEvent): boolean {
    return this.fakeClickInvoked
      && event.type === EventType.IncrementalSnapshot
      && event.data.source === IncrementalSource.MouseInteraction;
  }

  async destroy(): Promise<void> {
    if (this.canDisconnect()) {
      await this.close();
    }

    this.destroySource.next();
    this.destroySource.complete();
    this.canvasOverlay.destroy();
    this.remoteCursor.destroy();
    this.statusChangesSource.complete();
    this.agentDataChangesSource.complete();
    this.stopTempSwitchListener();
    this.clearInterface();

    CoBrowseHostSessionImpl.activeSession = undefined;
  }

  close(): Promise<void> {
    return this.disconnect(CoBrowseDisconnectReason.SessionClosed);
  }
}
