import { fromEvent, Subscription } from 'rxjs';

export class LeaveConfirmDialogController {
  private static activeSubscription?: Subscription;

  static readonly legacyConfirmationMessage = 'Connection you\'ve established may not be restored.';

  static enable(): void {
    if (!this.activeSubscription) {
      this.activeSubscription = fromEvent<BeforeUnloadEvent>(window, 'beforeunload')
        .subscribe(event => {
          event.preventDefault();
          event.returnValue = this.legacyConfirmationMessage;
        });
    }
  }

  static disable(): void {
    this.activeSubscription?.unsubscribe();
    this.activeSubscription = undefined;
  }
}
