import { io } from 'socket.io-client';
import { fromEvent, Subscription } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import {
  CoBrowseHostSessionStatus,
  CoBrowseHostSession,
  CoBrowseDisconnectReason,
  EmbeddableInputEventType,
  EmbeddableInitConfig,
  EmbeddableBootstrapOption,
  AgentData,
} from '@24sessions/common';

import './css/omnichannel.scss';
import { AppInputConnector, AppOutputConnector, CreateInputConnector, CreateOutputConnector } from '../../core/omnichannel/app-connectors';
import { MeetingRoom24Settings, OmnichannelConfig, OmnichannelState } from '../../core/omnichannel/models';
import { CoBrowseHostSessionImpl } from '../../core/co-browsing/co-browse-host-session-impl';
import { LeaveConfirmDialogController } from '../../core/leave-confirm-dialog-controller';
import { Logger } from '../../core/logger';
import { getConnectionAddress } from '../../core/utils';

import { Omnichannel } from '../../core/omnichannel/models';
import { ViewController } from '../../core/view-controller';

const CO_BROWSING_CODE_FROM_LINK = /^#cobrowse-([0-9a-z]{4})[\s-]*([0-9a-z]{4})[\s-]*([0-9a-z]{4})[\s-]*$/i;

export class OmnichannelService implements Omnichannel {
  readonly widgetWindowWidth = 500;

  private coBrowseSession: CoBrowseHostSession | undefined;
  private resizeSubscription: Subscription | undefined;

  private iframe: HTMLIFrameElement;

  private readonly inputConnection: AppInputConnector;
  private readonly outputConnection: AppOutputConnector;

  private readonly initialState: OmnichannelState = {
    isLoaded: false,
    inMeeting: false,
    entering: false,
    audioMuted: false,
    videoMuted: false,
    screenSharingActive: false,
    participantCount: 0,
  };

  get config(): OmnichannelConfig {
    return this.settings.config;
  }

  get state(): OmnichannelState {
    return this.settings.state;
  }

  get isCobrowsingActive(): boolean {
    return !!this.coBrowseSession;
  }

  private readonly viewController: ViewController;

  constructor(
    private readonly settings: MeetingRoom24Settings,
    private readonly logger: Logger,
    readonly createInputConnector: CreateInputConnector,
    readonly createOutputConnector: CreateOutputConnector,
  ) {
    this.settings.state = {
      ...this.initialState,
      onEnteredMeeting: settings.state?.onEnteredMeeting,
      onLeftMeeting: settings.state?.onLeftMeeting,
      onLoaded: settings.state?.onLoaded,
    };

    this.setGoogleFont('icon?family=Material+Icons');
    this.setGoogleFont('css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,500;1,700&display=swap');

    this.iframe = this.initIframe(this.config, document.body);

    this.inputConnection = createInputConnector(this.config.baseUrl, this, this.logger);
    this.outputConnection = createOutputConnector(this.config.baseUrl, this.iframe, this.logger);

    this.viewController = new ViewController(document.body, this.iframe);
  }

  private embeddedURL(config: OmnichannelConfig): string {
    const init: EmbeddableInitConfig = {
      config: config.embeddableRoomConfig, // NOTE(mark 2021-11-24): this assigns embeddableRoomConfig.guest
      bootstrap: this.bootstrapOption(),
    };

    const initBase64 = window.btoa(JSON.stringify(init));
    const url = new URL(`${config.baseUrl}/${config.isTestEnv ? 'room/embedded' : 'embedded'}`);

    url.searchParams.set('init', initBase64);
    return url.href;
  }

  private setGoogleFont(font: string): void {
    const link: HTMLLinkElement = document.createElement('link');
    link.href = `https://fonts.googleapis.com/${font}`;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  }

  private initIframe(config: OmnichannelConfig, container: HTMLElement): HTMLIFrameElement {
    const iframe: HTMLIFrameElement = document.createElement('iframe');

    iframe.id = 'embbeded-mr';
    iframe.src = this.embeddedURL(config);
    iframe.allow = 'camera;microphone';

    Object.assign(iframe.style, { ...iframe.style, ...config.iframeConfig.style });

    container.appendChild(iframe);
    return iframe;
  }

  private setBranding(primaryColor: string): void {
    document.body.style.setProperty('--color-primary-24s', primaryColor);
  }

  private onCobrowsingWaiting(): void {
    this.outputConnection.sendMessage({ type: EmbeddableInputEventType.CobrowsingWaiting });
  }

  private onCobrowsingStarted(): void {
    this.outputConnection.sendMessage({ type: EmbeddableInputEventType.CobrowsingStarted });
  }

  private async onCobrowsingDisconnected(reason: CoBrowseDisconnectReason): Promise<void> {
    switch (reason) {
      case CoBrowseDisconnectReason.SessionClosed:
      case CoBrowseDisconnectReason.HostChanged:
      case CoBrowseDisconnectReason.SessionClosedRemotely:
        this.outputConnection.sendMessage({ type: EmbeddableInputEventType.CobrowsingStopped, reason });
        await this.cleanupCobrowsingSession();
        break;
      default:
        this.outputConnection.sendMessage({ type: EmbeddableInputEventType.CobrowsingConnectionError });
        await this.cleanupCobrowsingSession();
        break;
    }
  }

  private onCobrowsingAgentDataReceived(data: AgentData): void {
    this.outputConnection.sendMessage({ type: EmbeddableInputEventType.AgentData, data })
  }


  private async cleanupCobrowsingSession(): Promise<void> {
    await this.coBrowseSession?.destroy();
    this.coBrowseSession = undefined;
  }

  private bootstrapOption(): EmbeddableBootstrapOption | undefined {
    const locationHash = decodeURIComponent(location.hash);
    const parsedWithCode = locationHash.match(CO_BROWSING_CODE_FROM_LINK);
    if (!parsedWithCode) {
      return;
    }

    let code = '';
    let codePieceIdx = 1;
    while (parsedWithCode[codePieceIdx]) {
      code = code.concat(parsedWithCode[codePieceIdx]);
      codePieceIdx++;
    }

    location.hash = '';
    return { coBrowsing: code };
  }

  start(): void {
    this.inputConnection.listen();
  }

  onStopCobrowsing(): void {
    if (this.coBrowseSession) {
      this.coBrowseSession.close();
      return;
    }

    throw new Error('Cannot stop co-browsing since there is no active session.');
  }

  monitorWindowMode(): void {
    this.resizeSubscription?.unsubscribe();

    this.resizeSubscription = fromEvent(window, 'resize')
      .pipe(
        startWith({}),
        map(() => ({ width: window.innerWidth, innerHeight: window.innerHeight })),
        map(size => size.width < this.widgetWindowWidth),
        distinctUntilChanged(),
      )
      .subscribe(isFullscreen => {
        if (isFullscreen) {
          this.viewController.fullscreen();
        } else {
          this.viewController.windowed();
        }

        this.windowModeChanged();
      });
  }

  onMeetingRoomLoaded(event: MessageEvent<{ instance: string; open: boolean }>): void {
    this.outputConnection.sendMessage({ type: 'maximize' as any });
    this.state.isLoaded = true;
    if (event.data.instance) {
      this.state.instance = event.data.instance;
    }

    this.monitorWindowMode();
    if (event.data.open) {
      this.onOpenWidget();
    }

    if (typeof this.state.onLoaded === 'function') {
      this.state.onLoaded();
    }
  }

  onEnteringMeeting(): void {
    this.state.entering = true;
  }

  onMeetingEntered(): void {
    this.state.inMeeting = true;
    this.state.entering = false;

    if (typeof this.state.onEnteredMeeting === 'function') this.state.onEnteredMeeting();
  }

  onEnteringMeetingFailed(): void {
    this.state.entering = false;
  }

  onMeetingLeft(): void {
    this.state.inMeeting = false;
    this.state.entering = false;
    this.state.callType = undefined;
    if (typeof this.state.onLeftMeeting === 'function') this.state.onLeftMeeting();
  }

  onReceivedTheme(event: any): void {
    const primaryColor = `#${event.data.primaryColor}`;

    this.setBranding(primaryColor);
  }

  onResize(event: { data: { height: number, width: number } }): void {
    if (event.data.height !== undefined) {
      this.iframe.style.height = `${event.data.height}px`;
    }

    if (event.data.width !== undefined) {
      this.iframe.style.width = `${event.data.width}px`;
    }
  }

  onMutedStates(event: any): void {
    this.state.videoMuted = event.data.videoMuted;
    this.state.audioMuted = event.data.audioMuted;
    this.state.screenSharingActive = event.data.screenSharingActive;
  }

  onCallType(event: MessageEvent): void {
    this.state.callType = event.data.callType;
  }

  onParticipantUpdate(event: any): void {
    this.state.participantCount = event.data.participantCount;
  }

  onStartCobrowsing(event: MessageEvent): void {
    // TODO: Make sure this can never happen
    if (this.isCobrowsingActive) {
      return this.logger.log('Cobrowsing session already active');
    }

    const { code } = event.data;

    const instance = this.state.instance || new URL(this.config.baseUrl).host;
    const [connectionURL, path] = getConnectionAddress(instance);

    this.coBrowseSession = new CoBrowseHostSessionImpl(() => io(
      connectionURL,
      {
        path,
        transports: ['websocket', 'polling'],
        query: { instance, code },
      }
    ));

    this.coBrowseSession.statusChanges.subscribe((sessionChanges) => {
      switch (sessionChanges.status) {
        case CoBrowseHostSessionStatus.CoBrowsing:
          this.onCobrowsingStarted();
          break;
        case CoBrowseHostSessionStatus.WaitingForViewers:
          this.onCobrowsingWaiting();
          break;
        case CoBrowseHostSessionStatus.Disconnected:
          this.onCobrowsingDisconnected(sessionChanges.reason);
          break;
      }
    });

    this.coBrowseSession.agentChanges.subscribe((agentData) => {
      this.onCobrowsingAgentDataReceived(agentData.data);
    })
  }

  setLeavePageConfirmation(enabled: boolean): void {
    if (enabled) {
      LeaveConfirmDialogController.enable();
    } else {
      LeaveConfirmDialogController.disable();
    }
  }

  onCloseWidget(): void {
    this.viewController.collapse();
  }

  onOpenWidget(): void {
    this.viewController.open();
  }

  windowModeChanged(): void {
    this.outputConnection.sendMessage({
      type: EmbeddableInputEventType.WindowMode,
      windowMode: this.viewController.windowMode,
    });
  }
}
