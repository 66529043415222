import { Point, SequentialPoint, TemporaryPoint } from './models';

export class CanvasOverlay {
  static readonly canvasClassName = 'co-browse-canvas';

  private canvasElement: HTMLCanvasElement;

  private drawingPoints: TemporaryPoint[] = [];

  private drawingTickTimer?: number;

  readonly canvasContext: CanvasRenderingContext2D;

  drawingColor = '#3456fa';

  constructor(readonly rootElement: HTMLElement) {
    this.canvasElement = document.createElement('canvas');
    this.canvasElement.classList.add(CanvasOverlay.canvasClassName);
    this.rootElement.appendChild(this.canvasElement);

    this.canvasContext = this.canvasElement.getContext('2d') as CanvasRenderingContext2D;
    this.canvasContext.lineWidth = 5;

    this.startAutodrawing();
  }

  private fitCanvas(): void {
    this.canvasElement.width = window.innerWidth;
    this.canvasElement.height = window.innerHeight;
  }

  private deleteStalePoints(): void {
    // check if there are any points that need to expire
    const now = Date.now();
    this.drawingPoints = this.drawingPoints.filter(point => point.expire >= now);
  }

  private handleDrawingTick(): void {
    this.fitCanvas();
    this.deleteStalePoints();

    // clear canvas
    this.canvasContext.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);

    const curves: TemporaryPoint[][] = [];
    this.drawingPoints.forEach(coordinate => {
      if (coordinate.isFirst || curves.length < 1) {
        curves.push([coordinate]);
        return;
      }
      curves[curves.length - 1].push(coordinate);
    })
    curves.forEach(curve => this.drawCurve(curve));
  }

  drawCurve(points: Point[], tension = 1): void {
    const context = this.canvasContext;
    context.beginPath();
    context.lineWidth = 4;
    context.strokeStyle = this.drawingColor;
    context.moveTo(points[0].x, points[0].y);

    for (var i = 0; i < points.length - 1; i++) {
      const p0 = i > 0 ? points[i - 1] : points[0];
      const p1 = points[i];
      const p2 = points[i + 1];
      const p3 = i != points.length - 2 ? points[i + 2] : p2;

      const cp1x = p1.x + ((p2.x - p0.x) / 6) * tension;
      const cp1y = p1.y + ((p2.y - p0.y) / 6) * tension;

      const cp2x = p2.x - ((p3.x - p1.x) / 6) * tension;
      const cp2y = p2.y - ((p3.y - p1.y) / 6) * tension;

      context.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, p2.x, p2.y);
    }
    context.stroke();
  }

  addDrawingPoint(point: SequentialPoint): void {
    this.drawingPoints.push({ ...point, expire: Date.now() + 2500 });
  }

  startAutodrawing(): void {
    if (!this.drawingTickTimer) {
      this.drawingTickTimer = window.setInterval(() => this.handleDrawingTick(), 100);
    }
  }

  stopAutodrawing(): void {
    window.clearInterval(this.drawingTickTimer);
    this.drawingTickTimer = undefined;
  }

  destroy(): void {
    this.stopAutodrawing();
    this.canvasElement.remove();
  }
}
