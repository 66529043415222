import { Subject } from 'rxjs';

import { record } from 'rrweb';
import type { eventWithTime, listenerHandler, recordOptions } from 'rrweb/typings/types';

import { CoBrowsingRemoteConfig } from './models';
import { getBlockedClassesList, rrwebBlockedClassesConfig } from '../utils';

interface NodeWithCachedSnapshot extends Node {
  __sn?: unknown;
}

export class Recorder {
  static readonly alwaysBlockedClass = 'rr-block';

  static readonly mousemoveTransmissionThreshold = 150;

  static hasClearedOutdatedCache = false;

  static ensureOutdatedCacheCleared(): void {
    if (this.hasClearedOutdatedCache) {
      return;
    }

    let path: NodeWithCachedSnapshot[] = [document];
    while (path.length > 0) {
      const node = path.pop() as NodeWithCachedSnapshot;

      if ('__sn' in node) {
        delete node.__sn;
      }

      const childNodes = Array.from(node.childNodes || []);
      path = path.concat(childNodes);
    }

    this.hasClearedOutdatedCache = true;
  }

  private stopRecording?: listenerHandler;
  private recordConfig?: recordOptions<eventWithTime>;
  private recordEventSource = new Subject<eventWithTime>();
  private started = false;

  readonly updates = this.recordEventSource.asObservable();

  get isActive(): boolean {
    return !!this.stopRecording;
  }

  get isDestroyed(): boolean {
    return this.recordEventSource.closed;
  }

  private startRecording(config: recordOptions<eventWithTime>): void {
    this.stopRecording = record(config);
  }

  private notDestroyed(): void {
    if (this.isDestroyed) {
      throw new Error('The recorder has been destroyed.');
    }
  }

  start(configuration: Partial<CoBrowsingRemoteConfig>): void {
    this.notDestroyed();
    this.stop();
    this.started = true;
    this.recordConfig = {
      emit: event => this.recordEventSource.next(event),
      sampling: { mousemoveCallback: Recorder.mousemoveTransmissionThreshold },
    };

    const blockedClasses = getBlockedClassesList(configuration)
      .concat(Recorder.alwaysBlockedClass);
    this.recordConfig.blockClass = rrwebBlockedClassesConfig(blockedClasses);

    Recorder.ensureOutdatedCacheCleared();
    this.startRecording(this.recordConfig);
  }

  restart(): void {
    this.notDestroyed();
    this.stop();

    if (!this.recordConfig) {
      throw new Error('Cannot restart recording since it hasn\'t started yet.');
    }

    this.startRecording(this.recordConfig);
  }

  stop(): void {
    if (this.stopRecording) {
      this.stopRecording();
      this.stopRecording = undefined;
    } else if (this.started) {
      console.warn('[CoBrowse] Recording cannot be stopped.');
    }
  }

  destroy(): void {
    this.stop();
    this.recordEventSource.complete();
  }
}
