import { CoBrowsingRemoteConfig } from './co-browsing/models';

export class TemporarySwitch {
  private innerState = false;
  private timer?: number;
  private listeners: ((state: boolean) => void)[] = [];

  get state(): boolean {
    return this.innerState;
  }

  get isOn(): boolean {
    return this.state === true;
  }

  get isOff(): boolean {
    return this.state === false;
  }

  constructor(private msSwitchTime: number) {
  }

  private clearTimer(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = undefined;
  }

  private scheduleStateReset(): void {
    this.clearTimer();
    this.timer = window.setTimeout(
      () => this.off(),
      this.msSwitchTime,
    );
  }

  private notify(): void {
    this.listeners.forEach(listener => listener(this.state));
  }

  toggle(): boolean {
    if (this.innerState) {
      this.off();
    } else {
      this.on();
    }
    return this.innerState;
  }

  on(): void {
    if (!this.innerState) {
      this.innerState = true;
      this.notify();
    }
    this.scheduleStateReset();
  }

  off(): void {
    if (this.innerState) {
      this.innerState = false;
      this.notify();
    }
    this.clearTimer();
  }

  onStateChanges(listener: (state: boolean) => void): Function {
    this.listeners.push(listener);
    return () => {
      this.listeners = this.listeners.filter(activeListener => activeListener !== listener);
    };
  }
}

export function timeoutPromisified(milliseconds: number): Promise<void> {
  return new Promise(resolve => {
    setTimeout(resolve, milliseconds);
  });
}

export function rrwebBlockedClassesConfig(classList: string | string[]): string | RegExp | undefined {
  classList = typeof classList === 'string' ? [classList] : classList;

  if (!classList.length) {
    return;
  }

  if (classList.length === 1) {
    return classList[0];
  }

  return new RegExp(`^(${classList.join('|')})$`);
}

export function getBlockedClassesList(configuration: Partial<CoBrowsingRemoteConfig>): string[] {
  const blockedClasses = configuration.blockClasses ?? [];
  return typeof blockedClasses === 'string'
    ? [blockedClasses]
    : blockedClasses;
}

export function getConnectionAddress(instance: string, serverUrl?: string): [string, string] {
  if (!serverUrl) return [`https://${instance}`, '/cobrowsing'];

  const address = new URL(serverUrl);
  return [address.origin, serverUrl.replace(address.origin, '')];
}
