import { createAppInputConnector, createAppOutputConnector } from '../../core/omnichannel/app-connectors';
import { Logger } from '../../core/logger';
import { MeetingRoom24Settings } from '../../core/omnichannel/models';
import { OmnichannelService } from './omnichannel.service';

declare const MeetingRoom24s: MeetingRoom24Settings | undefined;

function inject24s(settings: MeetingRoom24Settings): void {
  const logger = new Logger();
  logger.log('inject24s called, inserting 24s iframe...');

  const omnichannel = new OmnichannelService(settings, logger, createAppInputConnector, createAppOutputConnector);
  omnichannel.start();
}

function bootstrap(): void {
  if (!MeetingRoom24s) {
    throw new Error('Missing "MeetingRoom24s" config on Window.');
  }

  if (!MeetingRoom24s.config.blockAutoInject) {
    inject24s(MeetingRoom24s);
  }
}

(function(): void {
  console.info('Loaded 24s snippet.js');
  try {
    bootstrap();
  } catch (error) {
    let errorMessage = 'Unexpected error';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    console.warn(`Omnichannel widget bootstrap error: ${errorMessage}`)
  }
})();
