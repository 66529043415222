import { EmbeddableWindowMode } from '@24sessions/common';

import { FrameClass } from './omnichannel';

export class ViewController {
  static readonly preservedStyles: ReadonlyArray<string> = ['overflow', 'overflow-x', 'overflow-y'];

  get windowMode(): EmbeddableWindowMode {
    return this._windowMode;
  }

  get isOpen(): boolean {
    return this._isOpen;
  }

  private _scrollingLocked = false;

  private _preservedOverflow: Record<string, [string, string]> | null = null;

  private _windowMode = EmbeddableWindowMode.Windowed;

  private _isOpen = false;

  constructor(readonly rootElement: HTMLElement, readonly widgetContainerElement: HTMLElement) {
    this.widgetContainerElement.classList.add(FrameClass.Container);
    this.widgetContainerElement.classList.add(FrameClass.Closed);
  }

  private shouldLockScrolling(): boolean {
    return this._windowMode === EmbeddableWindowMode.Fullscreen && this._isOpen;
  }

  lockScrolling(): void {
    if (this._scrollingLocked) {
      return;
    }

    this._preservedOverflow = {};
    for (let propName of ViewController.preservedStyles) {
      this._preservedOverflow[propName] = [
        this.rootElement.style.getPropertyValue(propName),
        this.rootElement.style.getPropertyPriority(propName),
      ]
    }

    this.rootElement.style.setProperty('overflow', 'hidden', 'important')
    this._scrollingLocked = true;
  }

  unlockScrolling(): void {
    if (!this._scrollingLocked) {
      return;
    }

    const preserved = this._preservedOverflow || {};
    for (let propName of Object.keys(preserved)) {
      const [value, priority] = preserved[propName];
      this.rootElement.style.setProperty(propName, value, priority);
    }

    this._preservedOverflow = null;
    this._scrollingLocked = false;
  }

  fullscreen(): void {
    this._windowMode = EmbeddableWindowMode.Fullscreen;
    this.widgetContainerElement.classList.add(FrameClass.Fullscreen);
    this.widgetContainerElement.classList.remove(FrameClass.Windowed);
    this.stateChanged();
  }

  windowed(): void {
    this._windowMode = EmbeddableWindowMode.Windowed;
    this.widgetContainerElement.classList.add(FrameClass.Windowed);
    this.widgetContainerElement.classList.remove(FrameClass.Fullscreen);
    this.stateChanged();
  }

  open(): void {
    this._isOpen = true;
    this.widgetContainerElement.classList.remove(FrameClass.Closed);
    this.widgetContainerElement.classList.add(FrameClass.Opened);
    this.stateChanged();
  }

  collapse(): void {
    this._isOpen = false;
    this.widgetContainerElement.classList.add(FrameClass.Closed);
    this.widgetContainerElement.classList.remove(FrameClass.Opened);
    this.stateChanged();
  }

  stateChanged(): void {
    if (this.shouldLockScrolling() && !this._scrollingLocked) {
      this.lockScrolling();
    } else if (this._scrollingLocked) {
      this.unlockScrolling();
    }
  }
}
