import { fromEvent } from 'rxjs';
import { first } from 'rxjs/operators';

import { Destructible } from '@24sessions/common';

import { Point } from './models';

export class RemoteCursor implements Destructible {
  static readonly cursorClassName = 'co-browse-cursor';
  static readonly hiddenClassName = 'co-browse-cursor--hidden';
  static readonly clickClassName = 'co-browse-cursor--active';

  private cursorElement: HTMLElement;

  private nameElement: HTMLElement;

  private destroyed = false;

  get isDestroyed(): boolean {
    return this.destroyed;
  }

  constructor(readonly rootElement: HTMLElement) {
    this.cursorElement = document.createElement('div');
    this.cursorElement.classList.add(RemoteCursor.cursorClassName);

    this.nameElement = document.createElement("span");
    this.nameElement.classList.add("cursor-label");
    this.nameElement.innerText = "Agent";
    this.cursorElement.append(this.nameElement);

    rootElement.appendChild(this.cursorElement);

    this.moveTo({
      x: Math.round(window.innerWidth / 2),
      y: Math.round(window.innerHeight / 2),
    });
  }

  private animateClick(): void {
    this.cursorElement.classList.add(RemoteCursor.clickClassName);
  }

  private resetClick(): void {
    this.cursorElement.classList.remove(RemoteCursor.clickClassName);
  }

  show(): void {
    this.cursorElement.classList.remove(RemoteCursor.hiddenClassName);
  }

  hide(): void {
    this.cursorElement.classList.add(RemoteCursor.hiddenClassName);
  }

  moveTo(coordinates: Point): void {
    this.cursorElement.style.top = coordinates.y + 'px';
    this.cursorElement.style.left = coordinates.x + 'px';
  }

  destroy(): void {
    this.destroyed = true;
    this.cursorElement.remove();
  }

  fakeClick(): void {
    this.animateClick();
    fromEvent(this.cursorElement, 'animationend')
      .pipe(first())
      .subscribe(() => this.resetClick());
  }

  agentName(name: string): void {
    this.nameElement.innerText = name
  }
}
